<template>
  <div class="container">
    <h2>
      {{ $t("comments") }}: {{ $t("allComments") }}
      <span class="text-muted">{{ $store.state.Comments.count }}</span>
    </h2>
    <h5 class="text-muted mt-2 mb-4">{{ $t("manageComments") }}</h5>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Tables
      :headers="headers"
      :items="getComments"
      :loading="getCommentsAreLoading"
      :searchPlaceholder="$t('searchPlaceHolder')"
      @change:search="SET_SEARCH"
    >
      <template #username="{ item }">
        <router-link :to="`/user/${item.userId}`">
          <img
            :src="`/images/${item.isActive === 1 ? 'success' : 'danger'}.svg`"
            width="19"
            alt="status-img"
          />
          {{ item.username }}</router-link
        >
      </template>
      <template #productTitle="{ item }">
        <router-link :to="`/product/${item.productId}`">{{
          item.productTitle
        }}</router-link>
      </template>
      <template #createdAt="{ item }">
        <small>{{ formatter(new Date(item.createdAt)) }}</small>
      </template>
      <template #point="{ item }">
        <span class="text-success" v-for="i in item.point" :key="'m' + i"
          >★</span
        >
        <span v-for="i in 5 - item.point" :key="'v' + i">☆</span>
      </template>
      <template #options="{ item }">
        <button
          @click="
            $router.replace({
              query: {
                ...$route.query,
                modal: `comment-${item.id}`,
              },
            })
          "
          class="btn btn-pill donate mx-1"
        >
          {{ $t("view") }}
        </button>
      </template>
    </Tables>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <CommentActionModal :comments="getComments"></CommentActionModal>
  </div>
</template>

<script>
import Tables from "../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import CommentActionModal from "./CommentActionModal.vue";
export default {
  components: { Tables, BoostrapPagination, CommentActionModal },
  data() {
    return {
      search: "",
      page: 1,
      limit: 10,
      headers: [
        {
          col: 12,
          md: 4,
          lg: 2,
          xl: 2,
          title: this.$t("commenter"),
          value: "username",
        },
        {
          col: 12,
          md: 8,
          lg: 2,
          xl: 2,
          title: this.$t("product"),
          value: "productTitle",
        },
        {
          col: 12,
          md: 8,
          lg: 7,
          xl: 7,
          title: this.$t("comment"),
          value: "text",
        },
        {
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
          title: this.$t("point"),
          value: "point",
        },
        {
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
          title: this.$t("creationDate"),
          value: "createdAt",
        },
      ],
    };
  },
  computed: {
    getCommentsAreLoading() {
      return this.$store.state.Comments.commentsAreLoading;
    },
    getComments() {
      return this.$store.state.Comments.comments.filter(
        (comment) => comment.page === this.page
      );
    },
    pages() {
      return Math.ceil(this.$store.state.Comments.count / this.limit);
    },
  },
  methods: {
    formatter,
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_LIST();
    },
    SET_DATE(d) {
      const date = new Date(d);
      return formatter(date);
    },
    SET_SEARCH(search) {
      this.search = search;
      this.GET_LIST();
    },
    async GET_LIST() {
      this.$store.commit("SET_COMMENTS_ARE_LOADING", true);
      await this.$store.dispatch("GET_COMMENTS", {
        page: this.page,
        search: this.search,
        limit: 10,
      });
      setTimeout(() => {
        this.$store.commit("SET_COMMENTS_ARE_LOADING", false);
      }, 100);
    },
  },
  mounted() {
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST();
  },
};
</script>
