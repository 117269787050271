<template>
  <Comments></Comments>
</template>

<script>
import Comments from "../components/Comments/index.vue";
export default {
  components: { Comments },
};
</script>
